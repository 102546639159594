<template>
  <div>
    <loading-overlay :active="Loading" :is-full-page="true" loader="bars" />
    <CRow class="mt-2">
      <CCol sm="12" class="mt-2">
        <CRow>
          <label class="required col-sm-4 text-right">{{$t('label.holidayType')}}</label>
          <CCol sm="6">
            <v-select 
              :options="tipoOptions" 
              :clearable="false" 
              v-model.trim="$v.TpHolidayId.$model"
              :is-valid="hasError($v.TpHolidayId)"
              :invalid-feedback="errorMessage($v.TpHolidayId)"
              :class="isTipoValid"
              >
                <template slot="option" slot-scope="option" >
                    <span :style="option.color">
                      &nbsp;
                    </span>              
                    {{ option.label }}
                </template>
            </v-select>
          </CCol>
          <CCol sm="2" lg="1" class="text-right align-self-center">
            <CButton color="danger" square size="sm" class="float-right" v-c-tooltip="'Limpiar campos'" @click="limpiarDatos" >
              <CIcon name="cil-brush-alt"/>
            </CButton>
          </CCol>
      </CRow>
    </CCol>
    <CCol sm="12" class="mt-2">
      <CRow>
        <label class="required col-sm-4 text-right">{{$t('label.holidayDayName')}}</label>
        <CCol sm="6">
          <CInput
            v-uppercase
            invalid-feedback="Campo Requerido. {A..Z ,-.*-/#()}"
            :is-valid="hasError($v.HolidayName)"
            size="sm"
            maxlength="250"
            v-model.trim="$v.HolidayName.$model"
          />
        </CCol>
      </CRow>
    </CCol>
    <CCol sm="12">
      <CRow>
        <label class="required col-sm-4 text-right">{{$t('label.holidayDate')}}</label>
        <CCol sm="6" >
          <vue-datepicker 
            class="w-100"
            date
            :editable="false"
            :lang="this.$i18n.locale"
            format="DD/MM/YYYY"
            v-model.trim="$v.FechaCompleta.$model"
            :is-valid="hasError($v.FechaCompleta)"
            invalid-feedback="Campo requerido."
            :append-to-body="false"
            >
          </vue-datepicker>
        </CCol>
        <CCol sm="2" lg="1" class="align-items-start">
          <CButton color="success" square size="sm" class="float-right" v-c-tooltip="'Actualizar Feriado'" :disabled="isDisabled" @click="evaluaStatus" >
            <CIcon name="cil-plus"/>
          </CButton>
        </CCol>
      </CRow>
    </CCol>
      <CCol sm="10" lg="11"  v-if="actDia">
        <label class="required">{{$t('label.status')}}</label><br />
        <CSelect
          v-model="Status"
          :value.sync="Status"
          :is-valid="statusSelectColor"
          :options="selectOptions"
        />
      </CCol>
      <CCol sm="12">
        <dataTableExtended
     class="align-center-row-datatable"
          :items="itemsFeriado"
          :fields="fieldsFeriado"
          :loading="LoadingFeriado"
          column-filter
          :noItemsView="tableText.noItemsViewText"
          :table-filter="tableText.tableFilterText"
          :items-per-page-select="tableText.itemsPerPageText"
          :items-per-page="tableText.itemsPerPage"
          hover
          small
          sorter
          pagination
        >
          <template #Status="{item}">
              <td class="text-center">
                <CBadge :color="getBadge(item.Status)">
                  {{ $t('label.'+item.Status) }}
                </CBadge>
              </td>
          </template>
          <template #Detalle="{item}">
            <td class="py-2">
              <CButton color="edit" class="mr-1" square size="sm" v-c-tooltip="{placement:'top-end', content: $t('label.edit')+' '+$t('label.holiday')}" @click="editar(item)" >
                <CIcon name="pencil" />
              </CButton>
            </td>
          </template>
        </dataTableExtended>
      </CCol>
    </CRow>
  </div>  
</template>

<script>
import DiaFeriadoValidations from '@/_validations/feriado/HolidayValidations';
import UpperCase  from '@/_validations/uppercase-directive';
import General from '@/_mixins/general';
import { mapState } from 'vuex';
import { DateFormater } from '@/_helpers/funciones';

function  fieldsFeriado(){
  return [
    { 
      key: 'Nro', 
      label: '#',
      _style: 'width:1%;'
    },
    { key: 'HolidayDs', label:this.$t('label.holiday'), _style: 'width:33%;'},
    { key: 'DateHoliday', label:this.$t('label.holidayDate'), _style: 'width:12%;', _classes:"text-center"},
    { key: 'Usuario', label:this.$t('label.user'), _style: 'width:16%;',},
    { key: 'Fecha', label:this.$t('label.date'), _style: 'width:11%;', _classes:"text-center"},
    { key: 'Status', label:this.$t('label.status') , _style: 'width:11%;', _classes:"text-center"},
    { 
      key: 'Detalle',
      label: '',
      sorter: false,
      filter: false,
      _style: 'width:5%;'
    }
  ];
}

//DATA
function data () {
  return {
    //MODELO
    TpHolidayId: '',
    HolidayCalendarId: '',
    HolidayName: '',
    FechaCompleta: new Date(),
    Status: 1,
    //VARIABLES
    Loading: false,    
    LoadingFeriado: false,
    actDia: false,
    itemsFeriado: [],
    tipoOptions: [],
    
  }
}

function listarTipoFeriado() {
  this.tipoOptions = [];
  let listado = Array;
  this.Loading = true;
  this.$http.get("TpHoliday-list", { Filter: 'ACTIVO' })
  .then(response => {
    listado = [...response.data.data];
    this.tipoOptions = listado.map(listado => Object.assign({}, this.tipoOptions, {
      value: listado.TpHolidayId,
      label: listado.TpHolidayName,
      color: 'padding: 1px 10px; border-radius: 15px; margin-right: 5px; background-color:'+listado.Color
    }));
  }).catch( err => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  })
  .then(() => {
    this.Loading= false;
  });
}

function listarDiasFeriados() {
  this.itemsFeriado = [];
  let anio = new Date().getFullYear();
  if (this.periodo!=null) anio = this.periodo;  
  let listado = Array;
  this.LoadingFeriado = true;
  this.$http.get("HolidayCalendar-by-year", { Year: anio })
  .then(response => {
    listado = [...response.data.data];
    this.itemsFeriado = listado.map(listado => Object.assign({}, this.itemsFeriado, {
      Nro: listado.Nro,
      HolidayCalendarId: listado.HolidayCalendarId,
      HolidayDs: listado.HolidayDs ? listado.HolidayDs:'N/A',
      Color: listado.Color,
      TpHolidayId: listado.TpHolidayId,
      TpHolidayName: listado.TpHolidayName,
      DateHoliday: listado.DateHoliday ? DateFormater.formatOnlyDateWithSlash(listado.DateHoliday) : 'N/A',
      Usuario: listado.TransaLogin ? listado.TransaLogin:'N/A',
      Fecha: listado.TransaRegDate ? DateFormater.formatOnlyDateWithSlash(listado.TransaRegDate) : 'N/A',
      Status: listado.Status,
      Estatus: listado.Status === "INACTIVO" ? 0: 1,
      FgActHolidayCalendar: listado.FgActHolidayCalendar,
      _classes: listado.Status === "INACTIVO" ? 'table-danger': ''
    }));
  }).catch( err => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  })
  .then(() => {
    this.LoadingFeriado= false;
  });
}

//METHOD
function isTipoValid() {
  return (this.TpHolidayId == null || this.TpHolidayId == "") ? 'no-valido' : 'valido';
}

function isFechaValid() {
  return (this.FechaCompleta == null || this.FechaCompleta == "") ? 'no-valido' : 'valido';
}

function evaluaStatus() {
  if (this.Status===0) {
    this.$swal.fire(this.alertProperties({
      text: `${this.$t('label.changeStatusQuestion')} ${this.HolidayName}?`,
    })).then((result) => {
      if (result.isConfirmed) {
        this.guardar();
      }
    });  
  } else this.guardar();
}
  
function guardar () {
  if (this.$v.$invalid) return false;
  this.Loading = true;
  let HolidayCalendarJson = [];
  let metodo = '';
  let ruta = '';
  let res = [];
  let arr_fecha = DateFormater.formatOnlyDateWithSlash(this.FechaCompleta).split("/");
  
  //alert(((date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1))) + '/' + ((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate())) + '/' + date.getFullYear());
  if (this.actualizar) {
    HolidayCalendarJson = {
      HolidayCalendarId: this.HolidayCalendarId,
      HolidayDs: this.HolidayName,
      TpHolidayId: this.TpHolidayId.value,
      Day: arr_fecha[0],
      Month: arr_fecha[1],
      Year: arr_fecha[2],
      Status: this.Status,
    };
    metodo = 'PUT';
    ruta = "HolidayCalendar-update";
  } else {
    HolidayCalendarJson = {
      TpHolidayId: this.TpHolidayId.value,
      HolidayDs: this.HolidayName,
      Day: arr_fecha[0],
      Month: arr_fecha[1],
      Year: arr_fecha[2],
    };
    metodo = 'POST';
    ruta = "HolidayCalendar-insert";
  }
  
  this.$http.ejecutar(metodo, ruta, HolidayCalendarJson, { root: 'HolidayCalendarJson' })
  .then(response => {
    res = [...response.data.data];
    this.limpiarDatos();
    this.$notify({
      group: 'container',
      title: '¡'+this.$t('label.success')+'!',
      text: res[0].Response,
      type: "success"
    });
    this.listarDiasFeriados();
  }).catch((err) => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  })
  .then(() => {
    this.Loading= false;
  });
}

function limpiarDatos() {
  this.HolidayName = "";
  this.TpHolidayId = "";
  this.FechaCompleta = new Date();
  this.Status = 1;
  this.actDia = false;
  this.$nextTick(() => { this.$v.$reset() })
}

function editar(item) {
  this.HolidayCalendarId = item.HolidayCalendarId;
  this.TpHolidayId = {
    value: item.TpHolidayId,
    label: item.TpHolidayName,
    color: 'padding: 1px 10px; border-radius: 15px; margin-right: 5px; background-color:'+item.Color
  }
  this.HolidayId = item.HolidayId;
  this.HolidayName = item.HolidayDs;
  let fecha = item.DateHoliday.split("/");
  fecha = new Date(fecha[1]+"/"+fecha[0]+"/"+fecha[2]);

  this.FechaCompleta = fecha;
  this.Status = item.Estatus;
  this.actualizar = true;
  this.actDia = true;
}

//COMPUTED
function isDisabled() {
  return this.$v.$invalid && (this.FechaCompleta != '')
}
function selectOptions(){
  return [
        { 
          value: 1, 
          label: this.$t('label.ACTIVO')
        },
        { 
          value: 0, 
          label: this.$t('label.INACTIVO')
        }
      ];
}
//MOUNTED
/*function mounted() {
  this.listarTipoFeriado();
  this.listarDiasFeriados();
}*/

export default {
  name: 'feriado-modal',
  mixins: [General],
  data,
  props: {
    NuevoTipo: null,
    periodo: null
  },
  directives: UpperCase,
  validations: DiaFeriadoValidations,
  watch: {
    NuevoTipo: function () {
      this.limpiarDatos();
      if (this.NuevoTipo==true) {
        this.listarTipoFeriado();
        this.$emit('TipoVacio');
      } else {
        this.NuevoTipo.Estatus = 1;
        let fecha = this.NuevoTipo.DateHoliday.split(" ");
        fecha = fecha[0].split("-");
        this.NuevoTipo.DateHoliday = fecha[2]+"/"+fecha[1]+"/"+fecha[0];
        this.editar(this.NuevoTipo);
      }
    },
    periodo: function () {
      this.limpiarDatos();
      this.listarTipoFeriado();
      this.listarDiasFeriados();
    }
  },
  methods: {
    limpiarDatos,
    listarTipoFeriado,
    isTipoValid,
    isFechaValid,
    evaluaStatus,
    guardar,
    listarDiasFeriados,
    editar
  },
  //mounted,
  computed: {
    isDisabled,
    fieldsFeriado,
    selectOptions,
    ...mapState({
        user: state => state.auth.user,
    })
  }
}
</script>
<style scoped>

</style>